html {
  font-size: 100%;
}

@font-face {
  font-display: swap;
  font-family: "Untitled Sans Web";
  font-style: normal;
  font-weight: normal;
  src: url("UntitledSansWeb-Regular.woff2") format("woff2");
}

/*@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400&display=swap");
*/
a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
a:hover {
  color: #ffcd00;
}
div {
  box-sizing: border-box;
}
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  font-family: "Untitled Sans Web", -apple-system, system-ui, BlinkMacSystemFont, sans-serif;
  color: rgba(0, 0, 0, 0.4);
  font-size: 1em;
  line-height: 1.4;
  margin: 0;
  background-color: #dfdfdf;
}

.back {
  position: absolute;
  top: 0;
  left: 0;
  height: 210px;
  width: 100%;
  z-index: -1;
  background-color: #ffcd00;
}
img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin-left: calc(50% - 75px);
  border: 10px solid rgb(0, 2, 7, 1);
}

.box {
  position: absolute;
  top: 0;
  height: auto;
  width: 820px;
  left: calc(50% - 410px);

  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 40px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
  min-width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.box .top {
  padding: 60px;
  width: 100%;
  position: relative;
}
.box .buttom {
  position: relative;
  padding: 20px;
  width: 100%;
  background-color: #f6f8f8;
  margin-top: auto;
  display: flex;
}
.box .bottom span {
  padding-left: 15px;
}

.title {
  font-size: 2.5em;
  color: rgb(0, 2, 7);
  text-align: center;
  margin: 30px auto;
}
.subtitle {
  color: rgb(0, 2, 7);
  line-height: 1.5;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  flex: 1 1 0%;
}
.url {
  color: inherit;
  -webkit-text-decoration: underline;
  text-decoration: underline;
}
.contact {
  font-size: 1em;
  color: rgba(0, 0, 0, 0.35);
  font-family: monospace;
}
ul {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin: 0 auto;
  text-align: justify;
  line-height: 0;
  display: flex;
  flex-direction: column;
}
ul > li {
  list-style-type: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  line-height: 1.5;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
}

ul.c {
  width: auto;
  text-align: center;
}
ul.c > li {
  width: auto;
  padding-right: 20px;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 0px;
  margin-top: 10px;
}
.flex-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
li.head {
  color: #24292e;
}
.flex-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.fa-facebook:hover {
  color: #4166b2;
}
.fa-linkedin:hover {
  color: #0077b5;
}
.fa-github:hover {
  color: #24292e;
}
.box .top p.contact {
  margin: 180px auto 0px auto;
}
/* Use a media query to add a breakpoint at 800px: */
/* The width is 100%, when the viewport is 800px or smaller */
@media screen and (max-width: 800px) {
  .box {
    width: 80%;
    left: calc(10% - 2px);
  }
  .box .top {
    padding: 60px 25px;
  }
  ul > li {
    width: 100%;
  }
  .flex-row {
    flex-direction: column;
  }
}
